import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: function () {
      return import('../views/AboutView.vue')
    }
  },
  {
    path: '/product',
    name: 'product',
    component: function () {
      return import('../views/ProductView.vue')
    }
  },
  {
    path: '/marketing',
    name: 'marketing',
    component: function () {
      return import('../views/MarketingView.vue')
    },
  },
  {
    path: '/strategic',
    name: 'strategic',
    component: function () {
      return import('../views/StrategicView.vue')
    },
  },
  {
    path: '/martech',
    name: 'martech',
    component: function () {
      return import('../views/MartechView.vue')
    },
  },
  {
    path: '/software',
    name: 'software',
    component: function () {
      return import('../views/SoftwareView.vue')
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: function () {
      return import('../views/PrivacyView.vue')
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.afterEach((to, from) => {
  window.scrollTo(0, 0);
})

export default router
