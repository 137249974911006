<template>
  <div class="gototop flex items-center justify-center" @click="goToTop">
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
      <path d="M24.5 19.25L14 8.75L3.5 19.25" stroke="#FFFEF8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
</template>

<script setup>
const goToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}
</script>

<style lang="scss" scoped>
.gototop {
  width: 1em;
  height: 1em;
  font-size: 64px;
  border: 1px solid $white;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  transition: all .3s ease;
  svg path {
    transition: all .3s ease;
  }
  &:hover, &:active {
    border: 1px solid $prime;
    svg path {
      stroke: $prime;
    }
  }
}
</style>