import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { createPinia } from 'pinia';
import { MotionPlugin } from '@vueuse/motion';

import "@/assets/scss/style.scss"
import "@/assets/scss/text.scss"

const pinia = createPinia();
createApp(App).use(router).use(pinia).use(MotionPlugin).mount('#app')
