<template>
  <section class="howwework" ref="target">
    <h3
      class="section-title title3 expletus"
      v-motion
      :initial="{opacity: 0, y: -5}"
      :visibleOnce="{opacity: 1, y: 0, transition: { duration: 500 }}"
    >How we work</h3>
    <div id="followSection">
      <div v-if="showCircle" id="redCircle" class="flex items-center justify-center" :style="circleStyle"><p class="expletus text2">DRAG</p></div>
      <Slider
        v-motion
        :initial="{opacity: 0, y: -5}"
        :visibleOnce="{opacity: 1, y: 0, transition: { duration: 500 }}"
        @hover="handleHover"
      ></Slider>
    </div>
  </section>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useMouseInElement } from '@vueuse/core'
import Slider from "@/components/main/Slider.vue";

const target = ref(null)
const showCircle = ref(false)

const { elementX, elementY } = useMouseInElement(target)

const circleStyle = computed(() => ({
  left: `${elementX.value}px`,
  top: `${elementY.value}px`,
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  width: '120px',
  height: '120px',
  backgroundColor: '#FD5554',
  borderRadius: '50%',
  pointerEvents: 'none',
}));

const handleHover = (val) => {
  showCircle.value = val;
}
</script>

<style lang="scss">
.howwework {
  background: $text-dark;
  padding: 140px 0;
  overflow: hidden;
  position: relative;
  h3 {
    text-align: center;
    margin-bottom: 60px;
    @include breakpoint(medium) {
      margin-bottom: 40px;
    }
  }
}

#followSection {
  cursor: pointer;
}

#redCircle {
  z-index: 10;
  p {
    color: $white;
  }
}

@media (max-width: 700px) {
  .howwework {
    padding: 40px 0 60px;
  }
  #redCircle {
    display: none;
  }
}
</style>