<template>
  <div class="scroll-down flex flex-col items-center">
    <p
      class="text2"
      v-motion
      :initial="{opacity: 0, y: -10}" 
      :visibleOnce="{opacity: 1, y: 0, transition: { duration: 500, delay: 50 }}"
    >Scroll down</p>
    <div
      class="circle flex items-center justify-center"
      v-motion
      :initial="{opacity: 0, y: -10}" 
      :visibleOnce="{opacity: 1, y: 0, transition: { duration: 500, delay: 100 }}"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
        <path d="M14 5.31348C13.4199 5.31348 13.0156 5.71777 13.0156 6.29785V17.1523L13.0947 19.0596L10.8096 16.5107L8.81445 14.5244C8.63867 14.3486 8.39258 14.2344 8.11133 14.2344C7.5752 14.2344 7.17969 14.6387 7.17969 15.1924C7.17969 15.4473 7.27637 15.6846 7.4873 15.8955L13.2705 21.6963C13.4639 21.8984 13.7275 22.0039 14 22.0039C14.2637 22.0039 14.5273 21.8984 14.7207 21.6963L20.5127 15.8955C20.7236 15.6846 20.8203 15.4473 20.8203 15.1924C20.8203 14.6387 20.416 14.2344 19.8799 14.2344C19.6074 14.2344 19.3613 14.3486 19.1855 14.5244L17.1816 16.5107L14.9053 19.0508L14.9756 17.1523V6.29785C14.9756 5.71777 14.5713 5.31348 14 5.31348Z" fill="#FFFEF8"/>
      </svg>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.scroll-down {
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  gap: 14px;
  p {
    color: $white;
  }
  .circle {
    width: 58px;
    height: 58px;
    border: 1px solid $white;
    border-radius: 100%;
    overflow: hidden;
    svg {
      animation: move-bottom 2s linear infinite;
    }
  }
}

@keyframes move-bottom {
  0% {
    -moz-transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -moz-transform: translateY(120%);
    -webkit-transform: translateY(120%);
    transform: translateY(120%);
  }
}
</style>