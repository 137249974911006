<template>
  <section class="m-advantages">
    <div class="container">
      <div class="m-advantages__content flex flex-col items-center">
        <h3
          class="title3 expletus section-title"
          v-motion
          :initial="{opacity: 0, y: -10}" 
          :visibleOnce="{opacity: 1, y: 0, transition: { duration: 500, delay: 50 }}"
        >Our Advantages</h3>
        <div class="a-content flex" v-if="windowWdt > 900">
          <TextBlock
            v-for="i in items"
            :key="i.img"
            :title="i.title"
            :text="i.text"
            :img="i.img"
          ></TextBlock>
        </div>
        <swiper
          v-else
          ref="swiperRef"
          :slides-per-view="1"
          :space-between="20"
          @swiper="onSwiper"
          :modules="[Navigation]"
        >
          <swiper-slide v-for="(i, id) in items" :key="id">
            <TextBlock
              :title="i.title"
              :text="i.text"
              :img="i.img"
            ></TextBlock>
          </swiper-slide>
          <div class="controls flex items-center">
            <SliderControl
              @click="goPrev"
              :disabled="swiperInstance?.activeIndex === 0"
            ></SliderControl>
            <SliderControl
              @click="goNext"
              btnType="next"
              :disabled="swiperInstance?.activeIndex === items.length-1"
            ></SliderControl>
          </div>
        </swiper>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from "vue";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';
import TextBlock from "@/components/additional/TextBlock.vue";
import SliderControl from "@/components/additional/SliderControl.vue";
import getWindowWidth from "@/utils/getWindowWidth";
import 'swiper/css';

const props = defineProps({
  slides: Array,
})

const windowWdt = getWindowWidth();

const swiperInstance = ref(null);
const onSwiper = (swiper) => {
  swiperInstance.value = swiper;
};

const items = ref([
  {
    title: "Integrated Solutions",
    text: "At Relixo, we offer a seamless integration of software development and marketing services, providing our clients with a holistic approach to achieving their business objectives.",
    img: "adv1.jpg"
  },
  {
    title: "Expertise and Innovation",
    text: "With a team of seasoned professionals and a commitment to innovation, we stay ahead of the curve in both technology and marketing trends, ensuring that our clients benefit from the latest advancements in the industry.",
    img: "adv2.jpg"
  },
  {
    title: "Customized Solutions",
    text: "We understand that every business is unique. That's why we tailor our services to meet the specific needs and challenges of each client, delivering solutions that are both effective and personalized.",
    img: "adv3.jpg"
  },
])

const goNext = () => {
  if (swiperInstance.value) {
    swiperInstance.value.slideNext();
  }
};

const goPrev = () => {
  if (swiperInstance.value) {
    swiperInstance.value.slidePrev();
  }
};
</script>

<style lang="scss" scoped>
.m-advantages {
  padding: 100px 0;
  @include breakpoint(large) {
    padding: 80px 0;
  }
  @include breakpoint(small) {
    padding: 40px 0;
  }
  &__content {
    h3 {
      @include breakpoint(small) {
        text-align: center;
      }
    }
    .a-content {
      margin-top: 60px;
      gap: 100px;
      width: 100%;
      @include breakpoint(large) {
        gap: 40px;
        margin-top: 40px;
      }
      & > div {
        width: calc(100% / 3 - 50px);
        @include breakpoint(large) {
          width: calc(100% / 3);
        }
      }
    }
  }
}

@media (min-width: 1400px) {
  .m-advantages {
    &__content {
      padding: 0 60px;
    }
  }
}

.swiper {
  overflow: clip;
  margin-top: 40px;
  width: 100%;
  .swiper-slide {
    width: 100% !important;
  }

  .controls {
    gap: 14px;
    margin-top: 24px;
  }
}
</style>