<template>
  <Transition name="mob-menu">
    <div v-if="isOpen" class="menu flex flex-col justify-between">
      <div class="menu__header flex items-center justify-between">
        <RouterLink to="/">
          <Logo></Logo>
        </RouterLink>
        <div class="close" @click="isCrossClicked = true, $emit('close')">
          <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" fill="none">
            <path d="M0.458055 20.7324C-0.140783 21.3312 -0.169299 22.4004 0.472313 23.0277C1.09967 23.655 2.16902 23.6408 2.76786 23.042L11.4938 14.3025L20.2339 23.042C20.847 23.655 21.9021 23.655 22.5295 23.0277C23.1426 22.3862 23.1568 21.3454 22.5295 20.7324L13.8036 11.9929L22.5295 3.26764C23.1568 2.65459 23.1568 1.59958 22.5295 0.972276C21.8879 0.359228 20.847 0.344971 20.2339 0.958019L11.4938 9.69751L2.76786 0.958019C2.16902 0.359228 1.08541 0.330715 0.472313 0.972276C-0.155041 1.59958 -0.140783 2.66885 0.458055 3.26764L9.19823 11.9929L0.458055 20.7324Z" fill="#CBCBCB"/>
          </svg>
        </div>
      </div>
      <div class="menu__body">
        <Nav menu></Nav>
      </div>
      <div class="menu__footer flex flex-col">
        <div class="info flex justify-between items-end">
          <a href="/" target="_blank" class="text4 info__item no-underline text-prime">Linkedin</a>
          <a href="/" class="text4 info__item no-underline text-prime">info@rillius.com</a>
        </div>
        <div class="info flex justify-between items-end">
          <RouterLink to="/privacy" class="text4 info__item no-underline text-prime">Privacy Policy</RouterLink>
          <p class="text4 info__item text-prime">2024 Relixo Technologies</p>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup>
import { ref, watch, onUnmounted } from "vue";
import Logo from "@/components/common/Logo.vue";
import Nav from "@/components/common/Nav.vue";

const emit = defineEmits(['close']);
const props = defineProps({
  isOpen: Boolean
});

const isCrossClicked = ref(false);

watch(() => props.isOpen, () => {
  if(!props.isOpen) {
    isCrossClicked.value = false;
  }
})

onUnmounted(() => {
  document.body.classList.remove("no-scroll");
})
</script>

<style lang="scss">
.menu {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 9999;
  padding: 12.5px 20px 36px;
  &__footer {
    gap: 16px;
  }
  &__body {
    margin: 30px 0 40px;
    overflow-y: scroll;
    nav {
      overflow-x: hidden;
    }
  }
}

.mob-menu-enter-active,
.mob-menu-leave-active {
  transition: transform 0.5s ease;
}

.mob-menu-enter-from,
.mob-menu-leave-to {
  transform: translateX(100%);
}

.mob-menu-enter-to,
.mob-menu-leave-from {
  transform: translateX(0);
}

</style>