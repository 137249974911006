<template>
  <div class="container">
    <div @click="isModal = true" class="getintouch relative flex items-center justify-center">
      <p class="subtitle7">Get In Touch</p>
      <div class="overlay"></div>
    </div>
  </div>
  <Modal :isOpen="isModal" @close="isModal = false"></Modal>
</template>

<script setup>
import { ref } from "vue";
import Modal from "@/components/ui/Modal.vue";

const isModal = ref(false);
</script>

<style lang="scss" scoped>
.getintouch {
  background: url('@/assets/img/getintouch.jpg') no-repeat center/cover;
  height: 160px;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  transition: all .3s ease;
  cursor: pointer;
  @include breakpoint(small) {
    border-radius: 10px;
  }
  p {
    transition: all .3s ease;
    color: $white;
  }
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #00000043;
    opacity: 0;
    transition: all .3s ease;
  }
  &:hover {
    .overlay {
      opacity: 1;
    }
  }
  &:active {
    p {
      color: $prime;
    }
  }
}
</style>