<template>
  <nav class="flex items-center">
    <template
      v-for="nav in navs"
    >
      <RouterLink
        v-if="nav?.href !== ''"
        :to="nav.href"
        class="subtitle1 no-underline item"
      >{{ nav.label }}</RouterLink>
      <div 
        v-else-if="!!nav?.items"
        class="nav-dropped"
        ref="target"
        @click="handleClick"
      >
        <div
          class="nav-dropped__top subtitle1 item flex items-center"
          :class="{'active': isHovered}"
        >
          <p>{{ nav?.label }}</p>
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="9" viewBox="0 0 16 27" fill="none">
            <path d="M15.6881 13.7931C15.673 13.2796 15.4771 12.8394 15.0703 12.4433L3.34821 1.27814C3.00167 0.955357 2.59487 0.779297 2.09766 0.779297C1.08817 0.779297 0.304688 1.54223 0.304688 2.52523C0.304688 2.99473 0.500558 3.43488 0.847098 3.77233L11.394 13.7931L0.847098 23.8139C0.500558 24.1513 0.304688 24.5768 0.304688 25.061C0.304688 26.044 1.08817 26.8069 2.09766 26.8069C2.5798 26.8069 3.00167 26.6309 3.34821 26.3081L15.0703 15.1282C15.4922 14.7468 15.6881 14.3066 15.6881 13.7931Z" fill="#252522"/>
          </svg>
        </div>
        <div class="nav-dropped__hidden flex flex-col" :class="{'show': isHovered}">
          <RouterLink
            v-for="subnav in nav.items"
            :key="subnav?.href"
            :to="subnav.href"
            class="text3 no-underline item"
          >{{ subnav.label }}</RouterLink>
        </div>
      </div>
      <div v-else class="subtitle1 no-underline item clicked" @click="scrollToBlock(nav?.scroll)">
        {{ nav?.label }}
      </div>
    </template>
    
  </nav>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { onClickOutside } from '@vueuse/core'
import { useRouter } from 'vue-router';
import { useScrollStore } from "@/store/scroll";

const isHovered = ref(false);
const scrollStore = useScrollStore();
const router = useRouter();

const target = ref(null);

onMounted(() => {
  onClickOutside(target.value, event => {
    if(window.innerWidth > 700) {
      if(isHovered.value) isHovered.value = false;
    }
  })
})

const handleClick = () => {
  if(window.innerWidth <= 700) {
    isHovered.value = !isHovered.value;
  } else {
    isHovered.value = true;
  }
}

const navs = ref([
  {
    label: "About us",
    href: "/about"
  },
  {
    label: "Product",
    href: "/product"
  },
  {
    label: "Services",
    href: "",
    items: [
      {
        label: "Marketing Data Analytics",
        href: "/marketing"
      },
      {
        label: "Strategic Consulting",
        href: "/strategic"
      },
      {
        label: "Development of MarTech Software",
        href: "/martech"
      },
      {
        label: "Software Architecture",
        href: "/software"
      }
    ]
  },
  {
    label: "Contact",
    href: "",
    scroll: "contacts"
  }
])

const scrollToBlock = (index) => {
  document.body.classList.remove('no-scroll');
  if(router.currentRoute.value.name !== 'home') router.push('/');
  scrollStore.setBlockClicked(true);
  scrollStore.setBlockIndex(index);
  setTimeout(() => {
    scrollStore.setBlockClicked(false);
    scrollStore.setBlockIndex("");
  }, 500);

}

</script>

<style lang="scss" scoped>
nav {
  gap: 24px;
  height: 100%;
  a {
    color: $text-dark;
    text-transform: capitalize;
  }
  .item {
    padding: 10px 0;
    transition: all .3s ease;
    cursor: pointer;
    &:hover, &:active, &.active {
      color: $prime;
    }
    &:focus {
      color: $prime-200;
    }
  }
}

.nav-dropped {
  position: relative;
  p {
    cursor: pointer;
  }
  .item {
    padding: 0;
    svg {
      transform: rotate(90deg);
      transition: transform .4s ease;
    }
  }
  &__top {
    gap: 4px;
    p, svg {
      transition: all .2s ease;
    }
    &.active {
      svg {
        transform: rotate(-90deg);
      }
    }
    &:hover {
      p {
        color: $prime;
      }
      svg path {
        fill: $prime;
      }
    }
  }
  &__hidden {
    position: absolute;
    top: 30px;
    left: -60px;
    transform: translate(-50%, 0px);
    background: #fff;
    z-index: 10;
    border-radius: 12px;
    padding: 20px 24px;
    min-width: 270px;
    display: none;
    transition: all .2s ease;
    margin-top: 10px;
    border: 1px solid $grey-100;
    gap: 10px;
    &.show {
      display: flex;
    }
  }
}

@media (max-width: 700px) {
  nav {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
    a, .clicked {
      font-family: "Expletus Sans", sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 34px;
      padding: 28px 0 !important;
      border-bottom: 1px solid $grey-100;
      text-transform: math-auto;
      width: 100%;
    }
  }
  .nav-dropped {
    border-bottom: 1px solid $grey-100;
    padding: 28px 0;
    width: 100%;
    &__top svg {
      height: 15px;
      width: 15px;
    }
    &__hidden {
      position: static;
      transform: translate(0);
      border: none;
      border-radius: 0;
      padding: 10px 0;
      transition: display .3s ease;
      a {
        font-size: 24px;
        line-height: 28px;
        display: block;
        padding: 10px 0 !important;
        border-bottom: none;
        color: $grey-600;
      }
    }
    p {
      font-family: "Expletus Sans", sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 34px;
      text-transform: math-auto;
      width: 100%;
    }
  }
}
</style>