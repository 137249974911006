<template>
  <swiper
      ref="swiperRef"
      :slides-per-view="'auto'"
      :space-between="40"
      @swiper="onSwiper"
      :modules="[Navigation]"
  >
    <swiper-slide v-for="(slide, index) in slides" :key="index">
      <Slide
          :image="slide.image"
          :title="slide.title"
          :text="slide.text"
          :url="slide.url"
          @hover="sendHover"
      ></Slide>
    </swiper-slide>
  </swiper>
  <div class="flex items-center controls">
    <SliderControl
        @click="goPrev"
        :disabled="swiperInstance?.isBeginning"
        isWhite
    ></SliderControl>
    <SliderControl
        @click="goNext"
        btnType="next"
        isWhite
        :disabled="swiperInstance?.isEnd"
    ></SliderControl>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';
import Slide from "../additional/Slide.vue";
import SliderControl from "@/components/additional/SliderControl.vue";
import 'swiper/css';

const emit = defineEmits(["hover"]);

const sendHover = (val) => {
  emit("hover", val);
}

const swiperInstance = ref(null);
const onSwiper = (swiper) => {
  swiperInstance.value = swiper;
};

const slides = ref([
  {
    image: "slide1.jpg",
    title: "Data Analytics",
    text: "Uncover insights with our advanced analytics.",
    url: "/marketing"
  },
  {
    image: "slide2.jpg",
    title: "Strategic Consulting",
    text: "Navigate the intricacies of business strategy with confidence. ",
    url: "/strategic"
  },
  {
    image: "slide3.jpg",
    title: "MarTech Development",
    text: "Transform your marketing efforts with our innovative MarTech solutions.",
    url: "/martech"
  },
  {
    image: "slide4.jpg",
    title: "Software Architecture",
    text: "Stronger digital core. Expert architecture.",
    url: "/software"
  }
]);

const goNext = () => {
  if (swiperInstance.value) {
    swiperInstance.value.slideNext();
  }
};

const goPrev = () => {
  if (swiperInstance.value) {
    swiperInstance.value.slidePrev();
  }
};
</script>

<style lang="scss" scoped>
.swiper {
  overflow: clip;
  margin: 0 0 0 80px;
  padding-right: 80px;
  @include breakpoint(large) {
    margin: 0 0 0 60px;
    padding-right: 60px;
  }
  @include breakpoint(medium) {
    margin: 0 0 0 40px;
    padding-right: 40px;
  }
  @include breakpoint(small) {
    margin: 0 0 0 20px;
    padding-right: 20px;
  }

  &.visible {
    overflow: visible;
  }

  .swiper-slide {
    width: auto !important;
    @include breakpoint(medium) {
      width: calc(100% - 60px) !important;
    }
    @include breakpoint(small) {
      width: 100% !important;
    }
  }
}
.controls {
  display: flex;
  gap: 14px;
  margin: 60px 0 0 80px;
  @include breakpoint(large) {
    margin: 50px 0 0 60px;
  }
  @include breakpoint(medium) {
    margin: 40px 0 0 40px;
  }
  @include breakpoint(small) {
    margin: 30px 0 0 20px;
  }
}
</style>
