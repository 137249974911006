<template>
  <div class="marquee flex flex-col">
    <div class="top flex">
      <p class="title4">Data Analytics</p>
      <p class="title4">Strategic Consulting</p>
      <p class="title4">Data Analytics</p>
      <p class="title4">Strategic Consulting</p>
    </div>
    <div class="bottom flex">
      <p class="title4">Strategic Consulting</p>
      <p class="title4">MarTech Development</p>
      <p class="title4">Strategic Consulting</p>
      <p class="title4">MarTech Development</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.marquee {
  width: 100%;
  background: $text-dark;
  padding: 114px 0;
  gap: 34px;
  overflow: hidden;
  @include breakpoint(small) {
    padding: 88px 0;
  }
  .top, .bottom {
    gap: 110px;
    
    @include breakpoint(medium) {
      gap: 60px;
    }
    @include breakpoint(small) {
      gap: 44px;
    }
  }
  .top {
    -moz-animation: scroll-left 10s linear infinite;
    -webkit-animation: scroll-left 10s linear infinite;
    animation: scroll-left 20s linear infinite;
  }
  .bottom {
    -moz-animation: scroll-right 10s linear infinite;
    -webkit-animation: scroll-right 10s linear infinite;
    animation: scroll-right 20s linear infinite;
  }
  p {
    color: $white;
    white-space: nowrap;
    letter-spacing: 0.88px;
    text-transform: uppercase;
  }
}

@-moz-keyframes scroll-left {
  0% {
    -moz-transform: translateX(0);
  }
  100% {
    -moz-transform: translateX(-100%);
  }
}

@-webkit-keyframes scroll-left {
  0% {
    -webkit-transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes scroll-left {
  0% {
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@keyframes scroll-right {
  0% {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
</style>
