<template>
  <div class="home">
    <Header></Header>
    <Banner></Banner>
    <About></About>
    <Marquee></Marquee>
    <Advantages></Advantages>
    <GetInTouch></GetInTouch>
    <Product></Product>
    <HowWeWork ref="services"></HowWeWork>
    <Feedback ref="contacts"></Feedback>
    <Footer></Footer>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, onBeforeUnmount } from "vue";
import { useScrollStore } from "@/store/scroll";

import Header from "@/components/base/Header.vue";
import Banner from "@/components/main/Banner.vue";
import About from "@/components/main/About.vue";
import Marquee from "@/components/main/Marquee.vue";
import Advantages from "@/components/main/Advantages.vue";
import GetInTouch from "@/components/additional/GetInTouch.vue";
import Product from "@/components/main/Product.vue";
import HowWeWork from "@/components/main/HowWeWork.vue";
import Feedback from "@/components/additional/Feedback.vue";
import Footer from "@/components/base/Footer.vue";

const scrollStore = useScrollStore();
const contacts = ref(null);
const services = ref(null);

const scrollToBlock = (type) => {
  if(type === "services") {
    services.value.$el.scrollIntoView({ behavior: "smooth", top : 0});
  } else {
    contacts.value.$el.scrollIntoView({ behavior: 'smooth', top: 0 });
  }
  setTimeout(() => {
    scrollStore.setBlockClicked(false);
    scrollStore.setBlockIndex("");
  }, 500);

}

watch(() => scrollStore.blockIndex, (newValue, oldValue) => {
  if (newValue !== 0 && scrollStore.blockClicked) {
    scrollToBlock(scrollStore.blockIndex);
  }
})

onMounted(() => {
  if (scrollStore.blockIndex !== 0 && scrollStore.blockClicked) {
    setTimeout(() => {
      scrollToBlock(scrollStore.blockIndex);
    }, 0);
  }
})

onBeforeUnmount(() => {
  scrollStore.setBlockIndex("");
  scrollStore.setBlockClicked(false);
})
</script>
