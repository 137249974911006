<template>
  <div class="burger">
    <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.25" y="0.75" width="38.5" height="38.5" rx="9.25" stroke="#252522" stroke-width="1.5"/>
      <path d="M30.5 14L10.5 14" stroke="black" stroke-width="1.5" stroke-linecap="round"/>
      <path d="M30.5 20L10.5 20" stroke="black" stroke-width="1.5" stroke-linecap="round"/>
      <path d="M30.5 26L16.5 26" stroke="black" stroke-width="1.5" stroke-linecap="round"/>
    </svg>
  </div>
</template>

<style lang="scss" scoped>
.burger {
  display: block;
  cursor: pointer;
  height: 40px;
  width: 40px;
}

@media (min-width: 701px) {
  .burger {
    display: none;
  }
}
</style>