<template>
  <div class="b-form flex justify-between items-center">
    <div class="left flex flex-col">
      <div>
        <p
          class="subtitle6"
          v-motion
          :initial="{opacity: 0, y: -10}" 
          :visibleOnce="{opacity: 1, y: 0, transition: { duration: 500, delay: 0 }}"
        >Seeking personalized support?</p>
        <p
          class="subtitle6 red"
          v-motion
          :initial="{opacity: 0, y: -10}" 
          :visibleOnce="{opacity: 1, y: 0, transition: { duration: 500, delay: 50 }}"
        >{{ "Send a mail to our team :)" }}</p>
      </div>
      <form
        id="formm"
        @submit.prevent="send"
        class="flex flex-col"
        v-motion
        :initial="{opacity: 0}" 
        :visibleOnce="{opacity: 1, transition: { duration: 600, delay: 100 }}"
      >
        <div class="input flex flex-col" :class="{'error': isError && data.name.toString().trim().length == 0, 'border': data.name !== '' && !focus.name}">
          <span>Your name</span>
          <input @input="handleInput($event, 'name')" @focus="focus.name = true" @blur="focus.name = false" v-model="data.name" aria-required="true" maxlength="100" type="text"/>
          <svg class="warn" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM8 3C8.19891 3 8.38968 3.07902 8.53033 3.21967C8.67098 3.36032 8.75 3.55109 8.75 3.75V8.25C8.75 8.44891 8.67098 8.63968 8.53033 8.78033C8.38968 8.92098 8.19891 9 8 9C7.80109 9 7.61032 8.92098 7.46967 8.78033C7.32902 8.63968 7.25 8.44891 7.25 8.25V3.75C7.25 3.55109 7.32902 3.36032 7.46967 3.21967C7.61032 3.07902 7.80109 3 8 3ZM8 13C8.26522 13 8.51957 12.8946 8.70711 12.7071C8.89464 12.5196 9 12.2652 9 12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12C7 12.2652 7.10536 12.5196 7.29289 12.7071C7.48043 12.8946 7.73478 13 8 13Z" fill="#FD5554"/>
          </svg>
        </div>
        <div class="input flex flex-col" :class="{'error': isError && !emailRegex.test(data.email), 'border': data.email !== '' && !focus.email}">
          <span>Your email</span>
          <input type="email" v-model="data.email" maxlength="250" @focus="focus.email = true" @blur="focus.email = false"/>
          <svg class="warn" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM8 3C8.19891 3 8.38968 3.07902 8.53033 3.21967C8.67098 3.36032 8.75 3.55109 8.75 3.75V8.25C8.75 8.44891 8.67098 8.63968 8.53033 8.78033C8.38968 8.92098 8.19891 9 8 9C7.80109 9 7.61032 8.92098 7.46967 8.78033C7.32902 8.63968 7.25 8.44891 7.25 8.25V3.75C7.25 3.55109 7.32902 3.36032 7.46967 3.21967C7.61032 3.07902 7.80109 3 8 3ZM8 13C8.26522 13 8.51957 12.8946 8.70711 12.7071C8.89464 12.5196 9 12.2652 9 12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12C7 12.2652 7.10536 12.5196 7.29289 12.7071C7.48043 12.8946 7.73478 13 8 13Z" fill="#FD5554"/>
          </svg>
        </div>
        <div class="input flex flex-col" :class="{'border': data.subject !== '' && !focus.subject}">
          <span>subject</span>
          <input v-model="data.subject" @focus="focus.subject = true" @blur="focus.subject = false"/>
        </div>
        <div class="input textarea flex flex-col" :class="{'border': data.message !== '' && !focus.message}">
          <span>your message</span>
          <textarea v-model="data.message" @focus="focus.message = true" @blur="focus.message = false"></textarea>
        </div>
        <label class="custom-checkbox flex items-center" :class="{'checked': isChecked}" required>
          <input type="checkbox" id="ch-1" name="check" v-model="isChecked" required />
          <span class="checkmark"></span>
          <span class="check-text">
            I have read and accept the
            <RouterLink class="no-underline" to="/privacy">Privacy Policy</RouterLink>.
          </span>
        </label>
        <Button type="submit" outline>Send a request</Button>
      </form>
      <div v-if="inModal" class="flex bottom justify-between">
        <div class="flex items-center justify-between">
          <a class="text4">Linkedin</a>
          <a class="text4">Info@relixo.com</a>
        </div>
        <div class="copyright flex justify-between">
          <RouterLink to="/privacy" class="no-underline text4">Privacy</RouterLink>
          <p class="text4">2024 Relixo Technologies</p>
        </div>
      </div>
    </div>
    <div class="right" :class="{'in-modal': inModal}">
      <img
        src="@/assets/img/feedback.jpg"
        alt="relixo form picture"
        v-motion
        :initial="{opacity: 0}" 
        :visibleOnce="{opacity: 1, transition: { duration: 800 }}"
      />
    </div>
  </div>
  <ResponseModal :isSuccess="isSuccess" v-if="isFirst === 1" @close="isFirst = -1"></ResponseModal>
</template>

<script setup>
import { ref, watch, onUnmounted } from "vue";
import Button from "@/components/ui/Button.vue";
import ResponseModal from "@/components/ui/ResponseModal.vue";
const props = defineProps({
  inModal: Boolean
})

const data = ref({
  name: '',
  email: '',
  message: '',
  subject: '',
});

const focus = ref({
  name: false,
  email: false,
  message: false,
  subject: false,
})

const isChecked = ref(false);
const isError = ref(false);
const emailRegex = ref(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

const isSent = ref(false);
const isSuccess = ref(false);
const isFirst = ref(0);

watch(isSent, () => {
  if(!isSent.value) resetForm()
})

watch(isFirst, () => {
  if(isFirst.value === -1) resetForm();
})

const validate = () => {
  let error = false;
  Object.entries(data.value).map(([key, value]) => {
    if(key === 'name' && value.toString().trim().length === 0) error = true;
    if(key === 'email' && !emailRegex.value.test(value)) error = true;
  });
  return error;
}

const handleInput = (event,propertyName) => {
  data.value[propertyName] = event.target.value;
  if(isError.value) validate();
}

const resetForm = () => {
  data.value = {
    name: "",
    email: "",
    subject: "",
    message: "",
  }
  isSent.value = false;
  isChecked.value = false;
  isFirst.value = 0;
}

const fetchData = (formdata) => {
  const request = {
    method: 'POST',
    body: formdata,
    mode: 'no-cors',
  }

  fetch('https://license.darmius.kz/mailsend', request)
  .then(response => {
    isSent.value = true;
    isSuccess.value = true;
    isFirst.value = 1;
  })
  .catch(e => {
    isSent.value = true;
    isSuccess.value = false;
    isFirst.value = 1;
  })
}
const send = () => {
  isError.value = validate();
  if(!isError.value) {
    const formdata = new FormData();
    formdata.append('id', 'Relixo Website');
    formdata.append('name', 'User name: ' + data.value.name);
    formdata.append('email', 'User email: ' + data.value.email);
    formdata.append('emailto', "info@relixo.tech");
    formdata.append('message', 'Message: ' + data.value.message);
    formdata.append('subject', 'Relixo Website Request: ' + data.value.subject);

    fetchData(formdata)
  }
}

onUnmounted(() =>{
  resetForm();
})

</script>

<style lang="scss">
.b-form {
  width: 100%;
  form {
    max-width: 400px;
  }
  .bottom {
    display: none;
  }
  .subtitle6 {
    &.red {
      color: $prime;
    }
  }
  .left {
    width: 100%;
    white-space: nowrap;
    form {
      margin-top: 54px;
      gap: 16px;

      button {
        margin-top: 14px;
        width: 100%;
      }
    }
  }
  .right {
    width: 100%;
    max-width: 490px;
    img {
      width: 100%;
    }
  }
}

.input {
  border-radius: 12px;
  border: 1px solid $grey-100;
  position: relative;
  &:hover {
    border: 1px solid $grey-400;
  }
  &:has(input:focus),
  &:has(textarea:focus) {
    border: 1px solid $prime-300;
    span {
      color: $prime;
      opacity: 1;
    }
  }
  &.border {
    border: 1px solid rgba(37, 37, 34, 0.45);
  }
  .warn {
    display: none;
  }
  &.error {
    border: 1px solid $prime;
    input::placeholder, span {
      color: $prime;
    }
    span {
      opacity: 1;
    }
    .warn {
      display: block;
      position: absolute;
      top: 45%;
      right: 15px;
    }
  }
  & textarea {
    min-height: 100px;
    resize: none;
  }
  span {
    color: $grey-200;
    opacity: 0.4;
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    position: absolute;
    top: 8px;
    left: 14px;
    transition: all .2s ease;
  }
  input, textarea {
    outline: none;
    border: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    height: 100%;
    margin: 4px;
    padding: 20.5px 10px 5px;
  }
}

.custom-checkbox {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  position: relative;
  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
  }
  .check-text, .check-text a {
    color: $grey;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
  }
  & span:nth-child(3) {
    padding-left: 12px;
  }
  & + .custom-checkbox {
    margin-top: 12px;
  }

  & span > span {
    text-decoration: underline;
  }

  .checkmark {
    display: inline-block;
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
    border-radius: 6px;
    border: 1px solid $grey-100;
    background: none;
    cursor: pointer;
    transition: border .3s ease;
  }
  &:hover {
    cursor: pointer;
    input[type="checkbox"]:checked + .checkmark {
      background: $prime-400;
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url("@/assets/icons/check.svg");
    }
    .checkmark {
      border: 1px solid $grey-500;
    }
  }
  input[type="checkbox"]:checked + .checkmark {
    border: 1px solid rgba(255, 255, 255, 0.16);
    background: $prime;
    backdrop-filter: blur(13px);
    background-size: 50% 50%;
    border-radius: 6px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url("@/assets/icons/check.svg");
  }

  input[type="checkbox"]:checked + .checkmark::before {
    opacity: 1;
  }
}

@media (min-width: 1400px) {
  .b-form {
    padding: 0 36px 0 70px;
  }
}

@media (max-width: 900px) {
  .b-form {
    flex-direction: column-reverse;
    height: 100%;
    form {
      max-width: none;
    }
    .right {
      max-width: none;
      max-height: 550px;
      overflow: hidden;
      img {
        object-fit: cover;
        transform: translateY(-30%);
      }
      &.in-modal {
        display: none;
      }
    }
    .left {
      height: 100%;
    }
    .bottom {
      display: block;
      margin-top: auto;
      gap: 16px;
      a, p {
        color: $prime;
      }
    }
  }

}

@media (max-width: 700px) {
  .b-form {
    gap: 20px;
    .left {
      white-space: wrap;
      form {
        margin-top: 24px;
        input, textarea {
          font-size: 16px;
        }
      }
    }
    .right {
      max-height: none;
      img {
        transform: translateY(0);
      }
    }
  }
}
</style>