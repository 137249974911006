<template>
  <header>
    <div class="container">
      <div class="flex items-center justify-between">
        <div class="flex items-center left">
          <RouterLink to="/">
            <Logo></Logo>
          </RouterLink>
          <Nav></Nav>
        </div>
        <div class="flex items-center actions">
          <Button @click="isModal = true" primary>Contact Us</Button>
          <BurgerBtn @click="isMenu = true"></BurgerBtn>
        </div>
      </div>
    </div>
    <Menu :isOpen="isMenu" @close="isMenu = false"></Menu>
    <Modal :isOpen="isModal" @close="isModal = false"></Modal>
  </header>
</template>

<script setup>
import { ref, watch } from "vue";
import Logo from "@/components/common/Logo.vue";
import Nav from "@/components/common/Nav.vue";
import Button from "@/components/ui/Button.vue";
import BurgerBtn from "@/components/mobile/BurgerBtn.vue";
import Menu from "@/components/mobile/Menu.vue";
import Modal from "@/components/ui/Modal.vue";

const isMenu = ref(false);
const isModal = ref(false);

watch(() => isMenu.value, () => {
  if(isMenu.value) document.body.classList.add('no-scroll');
  else document.body.classList.remove('no-scroll');
})

</script>

<style lang="scss" scoped>
header {
  padding: 24px 0;
  .left {
    gap: 40px;

    @include breakpoint(medium) {
      gap: 20px;
    }
  }

  .actions {
    gap: 12px;
  }
}

@media (max-width: 700px) {
  header {
    nav {
      display: none;
    }
  }
}
</style>