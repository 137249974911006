<template>
  <footer>
    <div class="container">
      <div class="footer-content flex flex-col">
        <div class="flex footer-top relative">
          <GoToTop></GoToTop>
          <Logo light></Logo>
          <div class="actions flex">
            <div
              v-for="col in actions"
              :key="col.title"
              class="actions__col flex flex-col"
            >
              <p class="small3">{{ col?.title }}</p>
              <div class="actions__items flex flex-col">
                <template
                  v-for="p in col?.items"
                  :key="p.label"
                >
                  <p v-if="p.type === 'text'" class="small2 no-underline">{{ p?.label }}</p>
                  <a v-else-if="p.type === 'mail'" :href="`mailto:${p.label}`" class="small2 no-underline">{{ p?.label }}</a>
                  <p v-else-if="p.type === 'scroll'" class="small2 no-underline scrolled" @click="scrollToBlock(p.block)">{{ p?.label }}</p>
                  <RouterLink v-else-if="p.type === 'link'" :to="`/${p.href}`" class="small2 no-underline">{{ p?.label }}</RouterLink>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="flex footer-bottom justify-between">
          <a class="linkedin flex items-center justify-center" href="https://www.linkedin.com/company/relixo" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M4.82474 2.41736C4.82474 3.75242 3.74469 4.83471 2.41237 4.83471C1.08006 4.83471 0 3.75242 0 2.41736C0 1.08229 1.08006 0 2.41237 0C3.74469 0 4.82474 1.08229 4.82474 2.41736Z" fill="#FFFEF8"/>
              <path d="M0.329897 6.61157H4.45361V20H0.329897V6.61157Z" fill="#FFFEF8"/>
              <path d="M11.0928 6.61157H6.96907V20H11.0928C11.0928 20 11.0928 15.7851 11.0928 13.1498C11.0928 11.568 11.5969 9.97934 13.6082 9.97934C15.8814 9.97934 15.8677 12.0493 15.8571 13.653C15.8432 15.7492 15.8763 17.8884 15.8763 20H20V12.9339C19.9651 8.42197 18.8678 6.34298 15.2577 6.34298C13.1139 6.34298 11.7849 7.3858 11.0928 8.32927V6.61157Z" fill="#FFFEF8"/>
            </svg>
          </a>
          <div class="copyright flex items-end">
            <RouterLink to="/privacy" class="no-underline little2">Privacy</RouterLink>
            <p class="little2">2024 Relixo Technologies</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from 'vue-router';
import { useScrollStore } from "@/store/scroll";
import Logo from "@/components/common/Logo.vue";
import GoToTop from "@/components/additional/GoToTop.vue";

const scrollStore = useScrollStore();
const router = useRouter();

const actions = ref([
  {
    title: "Navigation",
    items: [
      {
        type: "link",
        label: "About Us",
        href: 'about'
      },
      {
        type: "link",
        label: "Product",
        href: "product"
      },
      {
        type: "scroll",
        block: "services",
        label: "Services",
      },
      {
        type: "scroll",
        block: "contacts",
        label: "Contacts",
      }
    ]
  },
  {
    title: "Services",
    items: [
      {
        type: "link",
        label: "Data Analytics",
        href: 'marketing'
      },
      {
        type: "link",
        label: "Strategic Consulting",
        href: "strategic"
      },
      {
        type: "link",
        label: "MarTech Development",
        href: "martech"
      },
      {
        type: "link",
        label: "Software Architecture",
        href: "software"
      }
    ]
  },
  {
    title: "Contact us",
    items: [
      {
        type: "text",
        label: "306 Victoria House, \nVictoria, Mahé, Seychelle",
      },
      {
        type: "mail",
        label: "info@relixo.tech"
      }
    ]
  }
])

const scrollToBlock = (index) => {
  document.body.classList.remove('no-scroll');
  if(router.currentRoute.value.name !== 'home') router.push('/');
  scrollStore.setBlockClicked(true);
  scrollStore.setBlockIndex(index);
}
</script>

<style lang="scss">
footer {
  background: $text-dark;
  .footer-content {
    padding: 60px 0 40px;
    gap: 94px;
  }
  .footer-top {
    gap: 150px;
  }
  .actions {
    gap: 81px;
    &__col {
      gap: 24px;
    }
    &__items {
      gap: 5px;
      p {
        margin-bottom: 5px;
        max-width: 160px;
        &:hover {
          color: $prime;
        }
      }
      a {
        cursor: pointer;
        transition: all .3s ease;
        &:hover {
          color: $prime;
        }
      }
      .scrolled {
        cursor: pointer;
      }
    }
    .small3 {
      letter-spacing: 0.56px;
      text-transform: uppercase;
      color: $prime;
    }
    .small2 {
      color: $white;
    }
  }
  .copyright { 
    gap: 18px;
    a, p {
      color: $grey-300;
      transition: all .3s ease;
    }
    a:hover {
      color: $prime;
    }
  }
  .linkedin {
    font-size: 50px;
    width: 1em;
    height: 1em;
    border-radius: 50px;
    border: 1px solid rgba(217, 217, 217, 0.13);
    svg path {
      transition: all .3s ease;
    }
    &:hover {
      border: 1px solid $prime;
      svg path {
        fill: $prime;
      }
    }
    &:active {
      border: 1px solid $prime-200;
      svg path {
        fill: $prime-200;
      }
    }
  }
}

@media (max-width: 1024px) {
  footer {
    .footer-top {
      flex-direction: column;
      gap: 60px;
    }
    .footer-content {
      gap: 40px;
    }
  }
}

@media (max-width: 700px) {
  footer {
    .actions {
      flex-direction: column;
      gap: 40px;
      &__col {
        gap: 14px;
      }
    }
    .footer-content {
      gap: 20px;
      padding: 40px 0 30px;
    }
    .footer-bottom {
      flex-direction: column;
      gap: 40px;
    }
  }
}
</style>